import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserMgmtComponent } from './user-mgmt/user-mgmt.component';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { RoleMgmtComponent } from './role-mgmt/role-mgmt.component';
import { RoleDetailComponent } from './role-mgmt/role-detail/role-detail.component';
import { ProfiletypeManagementComponent } from './profiletype-management/profiletype-management.component';
import { ProfiletypeDetailComponent } from './profiletype-management/profiletype-detail/profiletype-detail.component';
import { GroupMgmtComponent } from './group-mgmt/group-mgmt.component';
import { GroupDetailComponent } from './group-mgmt/group-detail/group-detail.component';
import { SingleGroupComponent } from './group-mgmt/single-group/single-group.component';

const routes: Routes = [
    {
        path: 'user',
        component: UserMgmtComponent,
        //canActivate: [AuthGuardService],
        // data: [{ path: ['user'] }]
    },
    {
        path: 'role',
        component: RoleMgmtComponent,
        //canActivate: [AuthGuardService],
        // data: [{ path: ['user'] }]
    },
    {
        path: 'role/create',
        component: RoleDetailComponent,
        //canActivate: [AuthGuardService],
        // data: [{ path: ['user'] }]
    },
    {
        path: 'role/edit/:id',
        component: RoleDetailComponent,
        //canActivate: [AuthGuardService],
        // data: [{ path: ['user'] }]
    },
    {
        path: 'profiletype',
        component: ProfiletypeManagementComponent,
        //canActivate: [AuthGuardService],
        // data: [{ path: ['user'] }]
    },
    {
        path: 'profiletype/create',
        component: ProfiletypeDetailComponent,
        //canActivate: [AuthGuardService],
        // data: [{ path: ['user'] }]
    },
    {
        path: 'group',
        component: GroupMgmtComponent,
       // canActivate: [AuthGuardService],
        data: [{ path: ['group'] }]
    }, {
        path: 'group/create',
        component: GroupDetailComponent,
        //canActivate: [AuthGuardService],
        data: [{ path: ['group'] }]
    },
    {
        path: 'group/create/:id',
        component: GroupDetailComponent,
        //canActivate: [AuthGuardService],
        data: [{ path: ['group'] }]
    },
    {
        path: 'group/edit/:editId',
        component: GroupDetailComponent,
        //canActivate: [AuthGuardService],
        data: [{ path: ['group'] }]
    },
    {
        path: 'group/:id',
        component: SingleGroupComponent,
       // canActivate: [AuthGuardService],
        data: [{ path: ['group'] }]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccessManagementRoutingModule { }